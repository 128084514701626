const ReactDOM = require('react-dom');
require("./src/styles/base.scss");

const loadableReady = require('@loadable/component').loadableReady;

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
        ReactDOM.render(element, container, callback);
    });
  };
};