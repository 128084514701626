// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-content-ceos-in-zoom-js": () => import("./../../../src/pages/content/ceos-in-zoom.js" /* webpackChunkName: "component---src-pages-content-ceos-in-zoom-js" */),
  "component---src-pages-content-feature-articles-js": () => import("./../../../src/pages/content/feature-articles.js" /* webpackChunkName: "component---src-pages-content-feature-articles-js" */),
  "component---src-pages-content-index-js": () => import("./../../../src/pages/content/index.js" /* webpackChunkName: "component---src-pages-content-index-js" */),
  "component---src-pages-content-learning-n-5-minutes-js": () => import("./../../../src/pages/content/learning-n5-minutes.js" /* webpackChunkName: "component---src-pages-content-learning-n-5-minutes-js" */),
  "component---src-pages-content-meet-the-team-js": () => import("./../../../src/pages/content/meet-the-team.js" /* webpackChunkName: "component---src-pages-content-meet-the-team-js" */),
  "component---src-pages-content-more-about-n-5-js": () => import("./../../../src/pages/content/more-about-n5.js" /* webpackChunkName: "component---src-pages-content-more-about-n-5-js" */),
  "component---src-pages-content-n-5-insights-js": () => import("./../../../src/pages/content/n5-insights.js" /* webpackChunkName: "component---src-pages-content-n-5-insights-js" */),
  "component---src-pages-content-now-you-know-js": () => import("./../../../src/pages/content/now-you-know.js" /* webpackChunkName: "component---src-pages-content-now-you-know-js" */),
  "component---src-pages-content-open-finance-js": () => import("./../../../src/pages/content/open-finance.js" /* webpackChunkName: "component---src-pages-content-open-finance-js" */),
  "component---src-pages-content-others-js": () => import("./../../../src/pages/content/others.js" /* webpackChunkName: "component---src-pages-content-others-js" */),
  "component---src-pages-content-pistacchio-ask-js": () => import("./../../../src/pages/content/pistacchio-ask.js" /* webpackChunkName: "component---src-pages-content-pistacchio-ask-js" */),
  "component---src-pages-content-rest-api-posts-slug-js": () => import("./../../../src/pages/content/{restApiPosts.slug}.js" /* webpackChunkName: "component---src-pages-content-rest-api-posts-slug-js" */),
  "component---src-pages-digital-america-js": () => import("./../../../src/pages/digital-america.js" /* webpackChunkName: "component---src-pages-digital-america-js" */),
  "component---src-pages-financial-access-js": () => import("./../../../src/pages/financial-access.js" /* webpackChunkName: "component---src-pages-financial-access-js" */),
  "component---src-pages-home-contact-js": () => import("./../../../src/pages/home/contact.js" /* webpackChunkName: "component---src-pages-home-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-market-research-js": () => import("./../../../src/pages/market-research.js" /* webpackChunkName: "component---src-pages-market-research-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-schedule-demo-js": () => import("./../../../src/pages/schedule-demo.js" /* webpackChunkName: "component---src-pages-schedule-demo-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-apply-js": () => import("./../../../src/pages/work/apply.js" /* webpackChunkName: "component---src-pages-work-apply-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */)
}

